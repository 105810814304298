<template>
  <div>
    <b-modal
      v-model="ownInsertControl"
      title-class="h3 text-white font-weight-bolder"
      size="sm"
      title="CREATE CAMPAIGN"
      @hidden="closeInsertModal"
      scrollable
    >
      <ValidationObserver ref="formNewCampain">
        <ValidationProvider
          v-slot="{ errors }"
          name="newcampaign"
          rules="required"
        >
          <b-form-group id="input-group-1" label="Campaign" label-for="input-1" class="my-1">
            <b-form-input
              id="newcampaign"
              v-model="newCampaign"
              type="text"
              placeholder="Enter campaign"
              required
              :state="errors[0] ? false : null"
              :class="{ 'is-border-danger': errors[0] }"
            />
          </b-form-group>
        </ValidationProvider>
      </ValidationObserver>

      <template #modal-footer>
        <b-button
          :disabled="creatingCampaign"
          variant="primary"
          @click="createCampaign"
        >
          <span v-if="!creatingCampaign">
            SAVE
          </span>
          <span v-else>
            <b-spinner small class="mr-2"></b-spinner>Loading...
          </span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="ownControl"
      title-class="h3 text-white font-weight-bolder"
      hide-footer
      size="xmd"
      title="FLYER"
      no-close-on-backdrop
      scrollable
      no-close-on-esc
      @hidden="closeModal"
    >
      <div class="ml-2">
        <b-container>
          <ValidationObserver ref="form">
            <b-row>
              <b-col
                class="
                  p-2
                  d-flex
                  align-content-center align-items-center
                  justify-content-center
                  position_img
                "
                xl="4"
                xs="7"
              >
                <b-row v-if="!updateImage && operationType !== 2">
                  <b-col>
                    <b-row>
                      <div>
                        <b-img-lazy
                          class="img"
                          v-bind="mainProps"
                          :src="item.route_thumb"
                        />
                      </div>
                    </b-row>
                    <b-row>
                      <feather-icon
                        @click="switchCancelOrUpdate"
                        icon="TrashIcon"
                        title="DELETE"
                        size="20"
                        class="cursor-pointer m-1 mx-auto"
                      />
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-if="updateImage">
                  <b-col>
                    <b-row>
                      <drag-and-drop
                        class="mb-0"
                        :class="{ 'is-border-danger' : files.length == 0 && uploadAttempt}"
                        v-model="files"
                        image="image"
                        :filesArray="[]"
                      ></drag-and-drop>
                    </b-row>
                    <b-row>
                      <feather-icon
                        @click="switchCancelOrUpdate"
                        v-if="operationType !== 2"
                        icon="XCircleIcon"
                        title="CANCEL"
                        size="20"
                        class="cursor-pointer m-1 mx-auto"
                      />
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="8" xs="7" class="pt-2 fa">
                <b-row>
                  <b-col lg="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="program"
                      rules="required"
                    >
                      <b-form-group class>
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-input-group-text
                              class="bg-primary text-white"
                              style="padding-right: 5.25em"
                            >
                              <span>PROGRAM</span>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <div
                            class="
                              d-flex
                              align-items-center
                              justify-content-around
                              w-75
                            "
                          >
                            <div
                              v-for="program in programs"
                              :key="program.id"
                              @change="reloadCampaign"
                                :state="errors[0] ? false : null"
                                :class="{ 'is-border-danger': errors[0] }"
                            >
                                <label class="d-flex">
                                  <b-form-radio
                                    id="program"
                                    v-model="itemCopy.program_id"
                                    plain
                                    class="mr-1"
                                    :value="program.id"
                                    name="program"
         
                                  >
                                  </b-form-radio>
                                  <figure>
                                    <b-img
                                      :style="cropImage(program.id)"
                                      :src="`${baseImg}/assets${program.logo}`"
                                    />
                                    <figcaption class="font-weight-bold font-figcaption">
                                      {{program.value}}
                                    </figcaption>
                                  </figure>
                                </label>
                            </div>
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="state"
                      rules="required"
                    >
                      <b-form-group class>
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-input-group-text
                              class="bg-primary text-white"
                              style="padding-right: 7.15em"
                            >
                              <span>STATE</span>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <div class="w-75">
                            <v-select
                              id="state"
                              :calculate-position="withPopper"
                              v-model="itemCopy.state"
                              :options="states"
                              @input="reloadCampaign"
                              label="state"
                              :reduce="(val) => val.slug"
                              :state="errors[0] ? false : null"
                              :class="{ 'is-border-danger': errors[0] }"
                            />
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="campaign"
                      rules="required"
                    >
                      <b-form-group class>
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-input-group-text
                              class="bg-primary text-white"
                              style="padding-right: 5em"
                            >
                              <span>CAMPAIGN</span>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <div class="w-75 d-flex">
                            <v-select
                              id="campaign"
                              class="w-100"
                              v-model="itemCopy.campaign_id"
                              :options="campaigns"
                              :reduce="(val) => val.id"
                              label="value"
                              :value="itemCopy.campaign"
                              :state="errors[0] ? false : null"
                              :class="{ 'is-border-danger': errors[0] }"
                            />
                            <feather-icon
                              @click="itemCopy.state ? openInsertModal() : null"
                              icon="PlusCircleIcon"
                              title="CREATE"
                              size="20"
                              class="mx-1 my-auto"
                              :class="{
                                'cursor-pointer': itemCopy.state,
                                'text-primary': itemCopy.state,
                              }"
                            />
                            <feather-icon
                              @click="
                                itemCopy.campaign_id ? deleteCampaign() : null
                              "
                              icon="TrashIcon"
                              title="DELETE"
                              size="20"
                              class="my-auto"
                              :class="{
                                'cursor-pointer': itemCopy.campaign_id,
                                'text-primary': itemCopy.campaign_id,
                              }"
                            />
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <div v-if="!updateImage">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="flyername"
                        rules="required"
                      >
                        <b-form-group class>
                          <b-input-group>
                            <b-input-group-prepend>
                              <b-input-group-text
                                class="bg-primary text-white"
                                style="padding-right: 4em"
                              >
                                <span>FLYER NAME</span>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <div class="w-75">
                              <b-input
                                id="fyername"
                                v-model="itemCopy.flyer_name"
                                style="padding-right: 8em"
                                type="text"
                                :state="errors[0] ? false : null"
                                :class="{ 'is-border-danger': errors[0] }"
                              />
                            </div>
                          </b-input-group>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="status"
                      rules="required"
                    >
                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-input-group-text
                              class="bg-primary text-white"
                              style="padding-right: 6.35em"
                            >
                              <span>STATUS</span>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <div class="w-75">
                            <v-select
                              id="status"
                              v-model="optionsStatus"
                              :options="['PREACTIVE', 'ACTIVE', 'INACTIVE']"
                              label="active"
                              @click="convert(item)"
                              :state="errors[0] ? false : null"
                              :class="{ 'is-border-danger': errors[0] }"
                            />
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="date"
                      rules="required"
                    >
                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-input-group-text class="bg-primary text-white">
                              <span>PUBLICATION DATE</span>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <div class="w-75">
                            <b-form-datepicker
                              id="date"
                              v-model="itemCopy.publication_date"
                              locale="en"
                              :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                              }"
                              :state="errors[0] ? false : null"
                              :class="{ 'is-border-danger': errors[0] }"
                            />
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-container>

        <b-row class="pt-1">
          <b-col class="pr-5 fa_obs" lg="12" xs="12">
            <b-form-group class="observation">
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text
                    class="bg-primary text-white observation-prepend"
                  >
                    <span>OBSERVATION</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <div class="observation-form" style="width: 100%">
                  <b-form-textarea
                    v-model="itemCopy.observation"
                    class="input-form observer"
                  />
                </div>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <div
          class="d-flex justify-content-center mb-1"
          v-if="operationType === 1"
        >
          <b-button variant="primary" @click="updateFlyer">
            <span v-if="!uploading">UPDATE</span>
            <span v-else>
              <b-spinner small class="mr-2"></b-spinner>Loading...
            </span>
          </b-button>
        </div>
        <div class="d-flex justify-content-center mb-1" v-else>
          <b-button variant="primary" @click="uploadFlyer">
            <span v-if="!uploading">SAVE</span>
            <span v-else>
              <b-spinner small class="mr-2"></b-spinner>Loading...
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import vSelect from "vue-select";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import BankOfFlyersService from "@/views/commons/components/bank-of-flyers/bank-of-flyers.service";
import SocialNetworkService from "@/views/commons/components/bank-of-flyers/services/social-network.service";
import {createPopper} from "@popperjs/core";

export default {
  components: {
    vSelect,
    DragAndDrop,
  },
  props: {
    item: {},
    operationType: null,
  },
  data() {
    return {
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      creatingCampaign: false,
      ownControl: false,
      ownInsertControl: false,
      campaigns: [],
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        height: 50,
        block: false,
        fluid: true,
        rounded: true,
        thumbnail: true,
      },
      changeOn: false,
      files: [],
      itemCopy: {},
      new_item: {
        active: true,
        state: "",
        program_id: "",
        campaign: "",
        files: [],
      },
      flyer_name: null,
      state_name: null,
      addCampaignOn: false,
      optionsStatus: "",
      newCampaign: "",
      showModalAddCampaign: false,
      updateImage: false,
      uploading: false,
      uploadAttempt: false,
    };
  },

  created() {
    this.ownControl = true;
    this.convert(this.item);
    this.itemCopy = { ...this.item };
    this.updateImage = this.operationType === 2; // Enable uploading images by default for operation create(2)
  },

  mounted() {
    if (this.operationType == 2) {
      this.itemCopy = {
        active: "",
        program_id: "",
        campaign_id: "",
        publication_date: "",
        active: "",
        observation: ".",
        state: "",
        flyer_name: "",
      };
    }
  },

  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    reloadCampaign() {
      this.itemCopy.campaign_id = "";
    },
    // eslint-disable-next-line consistent-return
    convert(item) {
      if (item.active === 0) {
        this.optionsStatus = "INACTIVE";
      } else if (item.active === 1) {
        this.optionsStatus = "ACTIVE";
      } else if (item.active === 2) {
        this.optionsStatus = "PREACTIVE";
      }
      return this.optionsStatus;
    },

    switchCancelOrUpdate() {
      this.updateImage = !this.updateImage;
    },

    titleInsertCampaign() {
      this.programs.forEach((program) => {
        if (program.id === this.itemCopy.program_id) {
          this.flyer_name = program.logo;
        }
      });
      this.state_name = this.itemCopy.state;
    },
    // eslint-disable-next-line consistent-return
    async getCampaigns() {
      try {
        const params = {
          program: this.itemCopy.program_id,
          state: this.itemCopy.state,
        };
        const { data } = await BankOfFlyersService.getCampaignFlyers(params);
        this.campaigns = data;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    //eslint-disable-next-line consistent-return
    async createCampaign() {
      const validation = await this.$refs.formNewCampain.validate();
      if (validation) {
        if (this.itemCopy.program_id == "" || this.itemCopy.state == "") {
          this.showErrorSwal("You must select a state and a program");
          return;
        }
        try {
          this.creatingCampaign = true;
          const params = {
            campaign: this.newCampaign,
            program: this.itemCopy.program_id,
            state: this.itemCopy.state,
            user: this.currentUser.user_id,
          };
          const data = await BankOfFlyersService.insertCampaignFlyers(params);
          if (data.status === 200) {
            await this.getCampaigns();
            this.showSuccessSwal();
            this.closeInsertModal();
            this.creatingCampaign = false;
          }
        } catch (e) {
          this.showErrorSwal(e);
          return [];
        }
      }
    },

    async deleteCampaign() {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          const params = {
            id: this.itemCopy.campaign_id,
          };
          const data = await BankOfFlyersService.deleteCampaignFlyers(params);
          if (data.status === 200) {
            await this.getCampaigns();
            this.reloadCampaign();
            this.showSuccessSwal();
          }
        } catch (e) {
          this.showErrorSwal(e);
        }
      }
    },

    closeModal() {
      this.$emit("close");
    },

    openInsertModal() {
      this.titleInsertCampaign();
      this.ownInsertControl = true;
    },

    closeInsertModal() {
      this.ownInsertControl = false;
      this.newCampaign = "";
    },

    async updateFlyer() {
      const validation = await this.$refs.form.validate();
      if (this.updateImage) {
        this.uploadAttempt = true
      }
      if (validation) {
        if( this.updateImage && this.files.length === 0 ){
          return;
        } else {
          this.uploadAttempt = false
        }
        const res = await this.showConfirmSwal();
        if (res.isConfirmed) {
          const formData = new FormData();
          if (this.files.length > 0) {
            this.files.forEach((file) => {
              formData.append("images[]", file, file.name);
            });
          } else {
            formData.append("images", "");
          }
          if (this.optionsStatus === "INACTIVE") {
            this.itemCopy.active = 0;
          } else if (this.optionsStatus === "ACTIVE") {
            this.itemCopy.active = 1;
          } else {
            this.itemCopy.active = 2;
          }
          formData.append("user_id", this.currentUser.user_id);
          formData.append("campaign_id", this.itemCopy.campaign_id);
          formData.append("publication_date", this.itemCopy.publication_date);
          formData.append("active", this.itemCopy.active);
          formData.append("observation", this.itemCopy.observation);
          formData.append("id_flyer", this.itemCopy.id);
          formData.append("flyer_name", this.itemCopy.flyer_name);
          const headers = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            }.bind(this),
          };
          this.uploading = true;
          this.addPreloader();
          await SocialNetworkService.updateFlyer(formData, headers.headers);
          this.uploading = false;
          this.removePreloader();
          this.$emit("update");
          this.$emit("close");
          this.redirectByActive();
        }
      }
    },

    async uploadFlyer() {
      const validation = await this.$refs.form.validate();
      this.uploadAttempt = true
      if (validation) {
        if( this.files.length === 0){
          return;
        } else {
          this.uploadAttempt = false
        }
        const res = await this.showConfirmSwal();
        if (res.isConfirmed) {
          const formData = new FormData();
          if (this.files.length > 0) {
            this.files.forEach((file) => {
              formData.append("images[]", file, file.name);
            });
          } else {
            formData.append("images", "");
          }
          if (this.optionsStatus === "INACTIVE") {
            this.itemCopy.active = 0;
          } else if (this.optionsStatus === "ACTIVE") {
            this.itemCopy.active = 1;
          } else {
            this.itemCopy.active = 2;
          }
          formData.append("user_id", this.currentUser.user_id);
          formData.append("campaign_id", this.itemCopy.campaign_id);
          formData.append("publication_date", this.itemCopy.publication_date);
          formData.append("active", this.itemCopy.active);
          formData.append("observation", this.itemCopy.observation);
          formData.append("flyer_name", this.itemCopy.flyer_name);
          const headers = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            }.bind(this),
          };
          this.uploading = true;
          this.addPreloader();
          await SocialNetworkService.uploadFlyer(formData, headers.headers);
          this.uploading = false;
          this.removePreloader();
          this.$emit("update");
          this.$emit("close");
          this.redirectByActive();
        }
      }
    },

    redirectByActive() {
      if (this.itemCopy.active === 0) {
        //Redirect according to value in active option
        this.$router
          .push({
              path: `/${this.currentModule}/bank-of-flyers/inactive`,
            })
          .catch( (err) => {
            if(
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
            ) {
              throw err;
            }
          });
        return;
      }
      if (this.itemCopy.active === 1) {
        this.$router
          .push({ path: `/${this.currentModule}/bank-of-flyers` })
          .catch( (err) => {
            if(
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
            ) {
              throw err;
            }
          });
        return;
      }
      if (this.itemCopy.active === 2) {
        this.$router
          .push({ path: `/${this.currentModule}/bank-of-flyers/preactive`})
          .catch( (err) => {
            if(
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
            ) {
              throw err;
            }
          });
        return;
      }
    },
    cropImage(id) {
      switch(id){
        case 1: //BC
          return {
            height: '31px',
            width: '70px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          }
        case 2:
          return { //BC
            height: '34px',
            width: '61px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          }
        case 3: //CE
          return {
            height: '32px',
            width: '57 px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          }
        case 4: // DS
          return {
            height: '32px',
            width: '57px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          }
        case 5: // TR
          return {
            height: '30px',
            width: '60px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          }
        case 6:
          return {
            height: '31px',
            width: '50px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          }
        case 8:
          return {
            height: '31px',
            width: '50px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          }
        default:
          break

      }
    }
  },

  computed: {
    currentModule() {
      return this.$route.meta.route;
    },

    ...mapState({
      programs: (state) => state.SocialNetworkGlobalStore.S_PROGRAMS,
      states: (state) => state.SocialNetworkGlobalStore.S_STATES,
    }),

    ...mapGetters({
      currentUser: "auth/currentUser",
      items: "ParagonBankOfFlyers/G_CAMPAIGNS",
    }),
  },

  watch: {
    "itemCopy.state": {
      async handler(newVal) {
        if (this.itemCopy.state && newVal) {
          this.addCampaignOn = true;
          await this.getCampaigns();
        }
      },
      deep: true,
    },
    "itemCopy.program_id": {
      async handler(newVal) {
        if (this.itemCopy.program_id && newVal) {
          this.addCampaignOn = true;
          await this.getCampaigns();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-select .v-input__control {
  overflow: hidden;
}

@media (max-width: 1380px) {
  .position_img {
    flex: 0 0 100.3333333333%;
    max-width: 100.3333333333%;
  }

  .observation {
    padding-left: 13px;
  }

  .img {
    width: 55%;
  }

  .fa {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .fa_obs {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .observation-prepend {
    max-width: 95%;
  }

  .observation-form {
    max-width: 77.5%;
  }

  .observer {
    margin-left: 6px !important;
  }
}
</style>
<style>
.is-border-danger {
  border: 1px solid red !important;
  border-radius: 0px 4px 4px 0px !important;
}

.font-figcaption {
  font-size: 10px !important;
  text-align: center !important;
  margin: 3px 0 0 0;
}
</style>
